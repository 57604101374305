import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Panic from '../../components/Specialties/components/Panic';

export default () => (
  <React.Fragment>
    <SEO
      title="Treatment for Panic Attacks & Agoraphobia | Mindset Family Therapy"
      description="Panic attacks are feelings and thoughts in your body that make you feel very distressed. Research has shown that cognitive behavioral therapy (CBT) is a well-established and highly effective treatment for panic disorders. Research also has shown that ACT (Acceptance & Commitment Therapy) can provide long lasting results."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498620468/panic.jpg"
      pathname="/specialties/panic"
      article
    />
    <Layout>
      <Panic />
    </Layout>
  </React.Fragment>
);
