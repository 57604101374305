import React from 'react';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import BookTeaser from '../../../BookTeaser';
import data from '../../../../data/panic';

import styles from './panic.module.scss';

const Panic = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498616697/anxiety.jpg';
  const image1 = 'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498620468/panic.jpg';

  return (
    <main>
      <PageHeader
        pageCategory="Specialties"
        pageName="Panic & Agoraphobia"
        headerImage={background}
      />

      <div className={styles.bookWrapper}>
        <BookTeaser bookName="Let Go of Anxiety" />
      </div>

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Panic attacks, Agoraphobia, and Panic Disorder</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.intro }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>What are Panic Attacks?</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.panicAttacks }} />
      </div>

      <QuoteImage image={image1} maxWidth={430} quote={data.quote1} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Agoraphobia</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.agoraphobia }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Panic Disorder</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.panicDisorder }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Treatment</h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.treatment }} />
      </div>
    </main>
  );
};

export default Panic;
