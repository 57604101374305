const panic = {
  quote1: '<p>“We don’t see things as they are. We see them as we are.”</p><p>ANAIS NIN</p>',
  intro:
    'Before we talk about Agoraphobia and Panic Disorder, we need to talk about panic attacks because when people experience them, they often develop anxiety disorders such as these.',
  panicAttacks:
    'They are feelings and thoughts in your body that make you feel very distressed. You may experience the following: palpitations, pounding heart, or accelerated heart rate, sweating, trembling or shaking, sensations of shortness of breath or smother, feeling of choking chest pain or discomfort, nausea or abdominal distress, feeling dizzy, unsteady, lightheaded, or faint, derealization (feelings of unreality) or depersonalization (being detached from oneself), fear of losing control or going crazy, fear of dying, numbness or tingling sensations chills or hot flashes.<br/><br/>Basically, it’s not a good feeling and sensation but it is the reaction your body has to the alarm system (amygdala) in your brain that is actually not functioning well. Panic attacks may last just a few seconds or minutes, but the sufferer will feel like it lasted forever. Once the sensation and feeling has run its course, the person will feel weak and exhausted.<br/><br/>Individuals that don’t address panic attacks will develop serious anxiety disorders as they will increase in frequency and duration.  Usually people believe they are going crazy, this is their perception because their brain has sent “false” signals of danger to their whole body. This situation is called the “fight-or-flight” response. Individuals participate in therapy in order to “retrain” that alarm system so that it won’t go off when there really is no danger around them.',
  agoraphobia:
    'When people experience panic attacks, they will begin to avoid places where they experienced those attacks for fear of having them again. Thus, if they experience a panic attack at the grocery story, they will avoid going there. If it happens in a crowded bus or train, they will avoid those means of transportation.  The word agoraphobia is a Greek word meaning “fear of the market place.” However, agoraphobia not only happens “at the market place” or outdoors, it can also happen while being alone in your home. Studies show that approximately 3.2 million American adults ages 18-54, or about 2.2 percent of people in this age group in a given year have agoraphobia.',
  panicDisorder:
    'Individuals have sudden and persistent attacks of fear that may last several minutes. They may not experience panic attacks but they feel great fear about having one. They constantly worry about the implications of the attack such as losing control, having a heart attack, or “going crazy.”  Their behavior changes significantly due to their avoidances and worries regarding having a panic attack. They may avoid going to the grocery store, driving, going to school or to work because their greatest fear is having a panic attack. People who struggle with panic disorder may also be challenged by depression.',
  treatment:
    'The treatment of choice for the disorders mentioned above is medication and psychotherapy. Research has shown that cognitive behavioral therapy (CBT) is a well-established and highly effective treatment for these disorders. Research also has shown that ACT (Acceptance & Commitment Therapy) can provide long lasting results. Psychoeducation is paramount. Clients are taught to become aware of their thoughts and then recognize how fused they are with them. They are taught skills to get untangled from their internal private events such as thoughts and learn to observe them instead of trying to get rid of them. Clients learn mindfulness exercises to look at anxiety in a different light. Exposures to fears will be done by taking steps to help clients retrain their “anxious mind.”',
};

export default panic;
